/* critical:start */

@import "reset.css";
/* @import "_menu.css"; */

/*--------------------------------------------------------------
# Settings
--------------------------------------------------------------*/
@custom-media --sm (width < 568px);
@custom-media --md (width < 768px);
@custom-media --lg (width < 1024px);
@custom-media --xl (width < 1450px); /* changed from 1280, then from 1220 */
@custom-media --menu-bp (width < 1000px);
@custom-media --mobile (width < 800px);
@custom-media --footer-tablet (width < 1480px);
@custom-media --footer-tablet2 (width < 1170px);

:root {
  --c-black: #000000;
  --c-blue: #051852;
  --c-cream: #fafaf5;
  --c-gold: #dcaf46;
  --c-grey: #6e6e69;
  --c-white: #ffffff;
}

@define-mixin blacker-bold {
  font-family: BlackerDisplay-Bold, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@define-mixin proxima {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@define-mixin proxima-semibold {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
}

:root {
  --scroll-padding-top: 0;
}

html {
  scroll-padding-top: var(--scroll-padding-top);
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Header Menu
--------------------------------------------------------------*/

body {
  --header-background-color: initial;
  --header-background-color-mobile: var(--c-blue);
  --header-height: 150px;
  --header-height-mobile: 70px;
  --header-width: 100%;
  --header-padding: 0 50px;
  --header-padding-mobile: 0 25px;

  --logo-width: 105px;
  --logo-width-mobile: 50px;

  /* Use margin or padding to offset menu  */
  --menu-margin: 25px 0 0 0;
  --menu-padding: 0;

  --menu-font-size: 17px;
  --menu-font-size-tablet: 15px;
  --menu-font-size-mobile: 17px;
  --menu-font-size-mobile-sub: 15px;
  --menu-letter-spacing: 0.1em;

  --menu-item-padding: 0 30px 0 0; /* Padding on list item */
  --menu-item-padding-mobile: 0;
  --menu-item-padding-mobile-sub: 0 0;
  --menu-item-padding-last-child: 0 0 0 0;

  --menu-link-padding: 20px 10px; /* Padding on link anchor */
  --menu-link-padding-mobile: 15px;
  --menu-link-padding-mobile-sub: 12px 0;
  --menu-link-color: rgb(255, 255, 255);
  --menu-link-color-hover: rgba(255, 255, 255, 0.75);

  --menu-color-current-page: rgba(255, 255, 255, 0.75);
  --menu-font: proxima-nova, sans-serif;
  --menu-font-weight: 400;
  --menu-transition: 0.3s;
  --menu-text-transform: uppercase;

  --hamburger-color: #ffffff;
  --hamburger-sticky-color: #ffffff;

  &.dark-header {
    --menu-link-color: var(--c-black);
    --menu-link-color-hover: rgba(0, 0, 0, 0.75);

    @media (--menu-bp) {
      --menu-link-color: var(--c-white);
      --menu-link-color-hover: rgba(255, 255, 255, 0.75);
    }

    --menu-color-current-page: rgba(0, 0, 0, 0.75);

    @media (--menu-bp) {
      --menu-color-current-page: rgba(255, 255, 255, 0.75);
    }

    .header__logo path {
      fill: var(--c-gold) !important;
    }

    .header__menu path {
      fill: var(--c-black);

      @media (--menu-bp) {
        fill: var(--c-white);
      }
    }
  }
}

.header__logo {
  a path {
    transition: 3s;
  }
  a:hover path {
    fill: var(--c-gold);
  }
}

.header__wrapper {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  padding: var(--header-padding);
  width: var(--header-width);
  width: 100%;

  @media (--menu-bp) {
    --header-padding: var(--header-padding-mobile);
  }
}

.header__rhs {
  display: flex;
}

.header__logo {
  width: var(--logo-width);

  @media (--menu-bp) {
    width: var(--logo-width-mobile);

    path {
      fill: var(--c-gold);
    }
  }
}

.site-header {
  background-color: var(--header-background-color);
  display: flex;
  height: var(--header-height);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;

  @media (--menu-bp) {
    background-color: var(--header-background-color-mobile);
    height: var(--header-height-mobile);
    position: fixed;
  }

  .admin-bar & {
    /* top: 32px; */
    top: 0;

    @media (--menu-bp) {
      top: 32px;
    }

    @media screen and (max-width: 782px) {
      top: 46px;
    }
  }
}

.content-area {
  /* margin-top: var(--header-height); */

  @media (--menu-bp) {
    margin-top: var(--header-height-mobile);
  }
}

/* Break point at which WP header scrolls away */
@media screen and (max-width: 600px) {
  .admin-bar {
    .site-header {
      position: static;
    }

    &.sticky .site-header {
      top: 0;
      position: fixed;
    }

    .content-area {
      margin-top: 0;
    }

    &.sticky .content-area {
      margin-top: var(--header-height-mobile);
    }
  }
}

#primary-menu {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: var(--menu-margin);
  padding: var(--menu-padding);
}

#primary-menu li {
  font-size: var(--menu-font-size);
  letter-spacing: var(--menu-letter-spacing);
  padding: var(--menu-item-padding);

  a {
    color: var(--menu-link-color);
    display: inline-block;
    font-family: var(--menu-font);
    font-weight: var(--menu-font-weight);
    padding: var(--menu-link-padding);
    text-transform: var(--menu-text-transform);
    transition: var(--menu-transition);
    text-decoration: none;

    &:hover {
      color: var(--menu-link-color-hover);
    }

    @media (--menu-bp) {
      display: block !important;
    }
  }
}

/* Hide 'Home' link on desktop version */
#primary-menu > li:first-child {
  display: none;

  @media (--menu-bp) {
    display: list-item;
  }
}

#primary-menu li:last-child {
  padding: var(--menu-item-padding-last-child);
}

.menu-main-menu-container {
  display: block;
}

.hamburger {
  display: none;
}

/*
 * Hamburger
 */

.hamburger:focus {
  outline-width: 0;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 0 15px;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: var(--hamburger-color);
  border-radius: 0;
  height: 2px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  width: 30px;

  .sticky & {
    background-color: var(--hamburger-sticky-color);
  }
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

#primary-menu li.current_page_item > a {
  color: var(--menu-color-current-page);
}

#primary-menu li.current_page_item path {
  fill: var(--menu-color-current-page);
}

.menu-open {
  overflow: hidden;
}

@media (--menu-bp) {
  #primary-menu .menu-item {
    padding: var(--menu-item-padding-mobile);
  }

  #primary-menu .sub-menu {
    display: none;
    list-style: none;
    padding: 0 20px; /* indent */
    width: 100%;

    &.toggled-on {
      display: block;
    }

    li {
      padding: var(--menu-item-padding-mobile-sub);

      a {
        padding: var(--menu-link-padding-mobile-sub);
      }
    }
  }

  .hamburger {
    display: inline-block;
  }

  .menu-container-container {
    background-color: var(--header-background-color);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 0;
    left: 0;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: var(--header-height-mobile);
    transition: opacity 0.5s;
    width: 100%;
    z-index: 1;

    .toggled & {
      height: calc(100% - var(--header-height-mobile));
      opacity: 1;
    }
  }

  .admin-bar .toggled .menu-container-container {
    height: calc(100% - var(--header-height-mobile) - 32px);
    top: calc(var(--header-height-mobile) + 32px);

    @media screen and (max-width: 782px) {
      top: calc(var(--header-height-mobile) + 46px);
      height: calc(100% - var(--header-height-mobile) - 46px);
    }
  }

  #primary-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: var(--header-padding-mobile);

    .menu-item {
      /* border-bottom: 1px solid #f3f3f3; */
      font-size: var(--menu-font-size-mobile);
      padding: var(--menu-item-padding-mobile);

      a {
        display: inline-block;
        padding: var(--menu-link-padding-mobile) 0;
      }

      &:last-child {
        /* border: unset; */
      }
    }
  }

  #primary-menu {
    .sub-menu {
      .menu-item {
        font-size: var(--menu-font-size-mobile-sub);
      }
    }
  }
}

/* There will be a slight gap if have scrolled more than 0 and less than 46px */
/* Break point at which WP header scrolls away */
@media screen and (max-width: 600px) {
  .admin-bar.sticky .toggled .menu-container-container {
    height: calc(100% - var(--header-height-mobile));
    top: var(--header-height-mobile);
  }
}

/*
 * Spin
 */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* Custom menu */
.header__menu {
  align-self: flex-start;

  @media (--menu-bp) {
    align-self: flex-end;
  }
}

.site {
  position: relative;
}

.header__logo {
  align-self: flex-end;
}

.menu-open {
  .header__wrapper {
    background-color: var(--c-blue);
  }

  .menu-container-container {
    background-color: var(--c-blue);
  }
}

/* DROPDOWN */

/* Structure: .main-navigation ul#primary-menu  */

.main-navigation ul ul {
  background-color: var(--c-blue);
  float: left;
  left: -999em;
  margin-left: 10px;
  opacity: 0;
  position: absolute;
  transition: opacity 1s;
  z-index: 99999;

  @media (--menu-bp) {
    opacity: 1;
  }
}

.main-navigation ul li:hover > ul,
.main-navigation ul li.focus > ul {
  left: auto;
  opacity: 1;
}

@media (--menu-bp) {
  #primary-menu li.menu-item-has-children {
    display: flex;
    flex-wrap: wrap;
  }
}

#primary-menu li li {
  padding: 10px 0 0;

  a {
    display: block;
    padding: 10px 24px;
    width: 280px;
  }
}

#primary-menu li li a {
  color: var(--c-white);

  &:hover {
    color: rgba(255, 255, 255, 0.75);
  }
}

#primary-menu li li:last-child {
  padding-bottom: 10px;
}

/* Mobile */
.dropdown-toggle {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
  text-shadow: none;

  &:focus {
    outline-width: 0;
  }

  @media (--menu-bp) {
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 3px;
  }
}

@media (--menu-bp) {
  .menu > li {
    line-height: normal;
    position: relative;
  }

  .main-navigation ul ul {
    float: none;
    position: static;
  }

  .main-navigation li li {
    padding-left: 20px;
  }

  button.toggled-on {
    transform: rotate(180deg);
  }
}

/* Tablet menu custom settings */
@media (--xl) {
  .header__wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }

  #primary-menu li {
    padding: 0 15px 0 0;
    font-size: var(--menu-font-size-tablet);
  }
}

/*--------------------------------------------------------------
# Header END
--------------------------------------------------------------*/

/* critical:end */

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

@define-mixin heading1 {
  @mixin blacker-bold;
  font-size: 100px;
  letter-spacing: 0.005em;
  line-height: 100px;

  @media (--mobile) {
    font-size: 48px;
    line-height: 45px;
  }
}

h1,
.heading1 {
  @mixin heading1;
}

.heading2 {
  @mixin blacker-bold;
  color: var(--c-black);
  font-size: 72px;
  line-height: 1em;

  @media (--mobile) {
    font-size: 36px;
    line-height: 40px;
  }
}

.heading3 {
  @mixin blacker-bold;
  color: var(--c-black);
  font-size: 60px;
  letter-spacing: 0.01em;
  line-height: 1em;

  @media (--menu-bp) {
    .page-template-services & {
      font-size: 24px;
    }

    @media (--mobile) {
      font-size: 24px;
    }
  }

  @media (--mobile) {
    font-size: 30px;
  }
}

@define-mixin heading4 {
  @mixin blacker-bold;
  color: var(--c-black);
  font-size: 48px;
  letter-spacing: 0.01em;
  line-height: 1em;

  @media (--mobile) {
    font-size: 26px;
    line-height: 1em;
  }
}

.heading4 {
  @mixin heading4;

  .section-copy--duncans-comments &,
  .page-template-honour-roll &,
  .page-template-contact & {
    @media (--mobile) {
      font-size: 48px;
    }

    @media (--sm) {
      font-size: 26px;
    }
  }

  .single-post & {
    @media (--mobile) {
      font-size: 48px;
    }

    @media (--sm) {
      font-size: 34px;
    }
  }

  .page-template-contact & {
    @media (--sm) {
      font-size: 30px;
    }
  }
}

.heading5 {
  @mixin blacker-bold;
  color: var(--c-black);
  font-size: 21px;
  letter-spacing: 0.01em;
  line-height: 1em;
}

.heading6 {
  @mixin blacker-bold;
  color: var(--c-black);
  font-size: 26px;
  letter-spacing: 0.005em;
  line-height: 30px;

  .post-type-archive-horses & {
    padding-top: 2.78vh;
  }

  .blog &,
  .archive &,
  .single-horses & {
    padding-top: 1vh;
  }

  @media (--sm) {
    font-size: 21px;
  }
}

@define-mixin heading7 {
  @mixin blacker-bold;
  color: var(--c-black);
  font-size: 26px;
  line-height: 30px;

  @media (--md) {
    font-size: 21px;
    line-height: 24px;
  }
}

.heading7 {
  @mixin heading7;
}

@define-mixin heading8 {
  @mixin blacker-bold;
  color: var(--c-black);
  font-size: 24px;
  line-height: 1em;

  @media (--md) {
    font-size: 21px;
  }
}

.heading8 {
  @mixin heading8;
}

@define-mixin copy {
  @mixin proxima;
  color: var(--c-grey);
  font-size: 20px;
  letter-spacing: 0.01em;
  line-height: 28px;
  margin: 0;
}

p,
.copy,
li {
  @mixin copy;
}

p + p {
  margin-top: 1em;
}

.semibold {
  @mixin proxima-semibold;
  color: var(--c-gold);
  font-size: 15px;
  letter-spacing: 0.01em;
  line-height: 45px;
  margin: 0;
}

.small {
  @mixin proxima;
  color: var(--c-grey);
  font-size: 15px;
  letter-spacing: 0.15em;
  line-height: 1em;
  padding-bottom: 25px;
  margin: 0;
}

/*--------------------------------------------------------------
# T1 Home
--------------------------------------------------------------*/

/* critical:start */
@custom-media --hero-bp (width < 800px);

.block_hero {
  --hero-height: 100vh;
  --hero-height-mobile: 80vh;
  --hero-width: 100vw; /* Including gutter */
  --hero-horizontal-padding: 0; /* Gutter */
  --hero-vertical-align: flex-start; /* flex-start, center, flex-end */
  --hero-padding-top: 0;
  --hero-padding-bottom: 0;
  --hero-overlay: rgba(0, 0, 0, 0.15);

  height: var(--hero-height);
  position: relative;
}

@media (--hero-bp) {
  .block_hero {
    --hero-height: var(--hero-height-mobile);
  }
}

/* Vertical container */
.block_hero__vertical {
  background-color: var(--hero-overlay);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: var(--hero-vertical-align);
  position: absolute;
  width: 100%;
}

.block_hero__img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;

  .home & {
    @media (width < 1600px) and (width >= 800px) {
      object-position: 75%;
    }

    @media (width < 800px) {
      object-position: 50% 30%;
    }
  }

  .page-template-farm-development & {
    object-position: 0% 50%;
  }

  .page-template-about & {
    object-position: 50% 50%;
  }
}

.block_hero__horizontal {
  align-items: center;
  margin-top: calc(var(--progress, 30) * 1vh);
  display: flex;

  @media (--menu-bp) {
    margin-top: calc(var(--progress, 30) * 0.25vh); /* Initial 7.5vh */
  }

  @media (--mobile) {
    /* margin-top: 55px; */
    margin-top: calc(var(--progress, 30) * 0.25vh); /* Initial 7.5vh */
  }
}

.block_hero__heading {
  padding-left: 28px;

  @media (--mobile) {
    padding-left: 25px;
  }
}

.line {
  border-top: 1px solid white;
  height: 1px;
  width: calc(50px + (100vw - 408px) / 12);

  @media (--mobile) {
    /* display: none; */
  }
}

.line--gold {
  border-color: var(--c-gold);
}

.white {
  color: #ffffff;
}

.gold {
  color: var(--c-gold);
}

/* Animation */

.section-copy.section-copy--article-info,
.section-copy.section-copy--article {
  visibility: hidden;
}

.block_hero .line,
.section-header .line {
  opacity: 0;
}

.block_hero__heading {
  opacity: 0;
}

.section-header__heading {
  opacity: 0;
}

/* Current Offers, Horses intro  */
.post-type-archive-horses .section-header__copy,
.post-type-archive-horses .section-3col--current-offers,
.post-type-archive-horses .section-copy--sold,
.post-type-archive-horses .section-3col--sold,
.horses-template-default .section-header__copy,
.horses-template-default .section-header__info,
.horses-template-default .section-mediagrid,
.page-template-honour-roll .section-copy__copy,
.page-template-honour-roll .section-copy--dgr-champions,
.page-template-honour-roll .accordion,
.blog .section-header__info,
.blog .section-split,
.page-template-contact .section-header__info,
.page-template-contact .section-copy--conversation {
  visibility: hidden;
}

/* critical:end */

.section-copy {
  column-gap: 28px;
  display: grid;
  grid-template-columns: calc(16px + (100vw / 12)) repeat(10, 1fr) calc(
      16px + (100vw / 12)
    );
  padding-top: 200px;
  padding-bottom: 200px;

  @media (--menu-bp) {
    column-gap: 0;
    grid-template-columns: 25px 1fr 25px;
  }

  @media (--md) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .line {
    align-self: center;
    margin-bottom: 5px;

    @media (--menu-bp) {
      grid-column: 1 / span 2;
      margin-bottom: 20px;
      width: 120px;
    }
  }
}

.section-copy--sold {
  padding-top: 100px;
  padding-bottom: 0;

  .section-copy__heading {
    grid-column: 2 / span 8;
  }
}

.section-copy--dgr-champions {
  padding-top: 130px;
  padding-bottom: 20px;
}

.section-copy--honour-roll-intro {
  padding-top: 0;
  padding-bottom: 0;
}

.section-copy--honour-roll {
  background-color: var(--c-cream);
  padding-bottom: 0;
}

.section-copy--trophyroom {
  background-color: white;
  padding-bottom: 0;
}

.section-copy--farm-intro {
  padding-bottom: 140px;
}

.section-copy--farm-dev {
  background-color: var(--c-cream);
  grid-template-columns: calc(16px + (100vw / 12)) repeat(11, 1fr);
  padding-top: 100px;
  padding-bottom: 0;
  padding-right: 50px;

  @media (--menu-bp) {
    grid-template-columns: 25px 1fr 25px;
    padding-right: 25px;
  }

  .section-copy__heading {
    grid-column: 2 / span 7;
  }

  .section-copy__copy {
    margin-top: 50px;
    padding-right: 3.2vw;

    @media (--menu-bp) {
      grid-column: 2 / span 10;
      grid-row: 4;
      margin-top: 25px;
    }
  }

  .section-copy__photo {
    grid-column: 9 / span 4;
    grid-row: 2;
    margin-top: 50px;

    @media (--menu-bp) {
      grid-column: 2 / span 7;
      grid-row: 3;
      margin-top: 25px;
    }
  }
}

.section-copy--bloodstock {
  padding-bottom: 50px;

  @media (--menu-bp) {
    padding-bottom: 0;
  }
}

.section-copy--services-cta {
  padding-top: 0;
  padding-bottom: 50px;
}

.section-copy--services {
  padding-bottom: 200px;

  @media (--menu-bp) {
    padding-bottom: 100px;
  }
}

.section-copy--bloodstock-cta {
  background-color: var(--c-cream);
  padding-bottom: 50px;

  @media (--menu-bp) {
    padding-top: 75px;
  }

  .line {
    align-self: center;
  }

  .section-copy__heading {
    grid-column-end: span 6;

    @media (width < 1720px) {
      grid-column-end: span 8;
    }

    @media (--menu-bp) {
      grid-column-end: span 1;
    }
  }
}

.section-copy--current-offers {
  padding-bottom: 50px;

  @media (--menu-bp) {
    padding-top: 100px;
    padding-bottom: 0;
  }

  @media (--menu-bp) {
    .line {
      display: block;
    }
  }
}

/* Add padding to slider intro text on honour roll but not home page */
@media (--menu-bp) {
  .page-template-honour-roll .section-copy--current-offers {
    padding-bottom: 50px;
  }
}

.section-copy--duncans-comments {
  background-color: var(--c-cream);
  padding-top: 120px;
  padding-bottom: 130px;

  @media (--menu-bp) {
    padding-top: 100px;
    padding-bottom: 65px;
  }

  .section-copy__copy p {
    @mixin heading7;
  }

  .section-copy__heading {
    grid-column: 2 / span 6;
  }
}

.section-copy--our-services {
  background-color: var(--c-blue);
  padding-bottom: 60px;

  @media (--menu-bp) {
    padding-top: 75px;
    padding-bottom: 50px;
  }

  .section-copy__heading {
    color: var(--c-white);
  }
}

.section-copy--photo-right {
  background-color: var(--c-cream);
  padding-top: 275px;
  padding-bottom: 230px;

  @media (--menu-bp) {
    padding-top: 80px;
    padding-bottom: 100px;

    .page-template-about & {
      padding-top: 150px;
    }
  }

  .section-copy__heading {
    grid-column: 2 / span 6;
  }

  .section-copy__copy {
    padding-right: 3.2vw;
  }
}

.section-copy--findoutmore {
  background-color: var(--c-cream);
  padding-top: 0;
  padding-bottom: 60px;
}

.section-copy__photo {
  grid-column: 8 / span 4;
  grid-row: 1 / span 4;

  img {
    max-width: 100%;
  }

  @media (--menu-bp) {
    margin-top: 40px;
    grid-column: 2;
    grid-row: 4;

    .home & {
      grid-row: 5;
    }
  }
}

.section-copy__button {
  grid-column: 2 / span 6;
  grid-row: 3;

  @media (--menu-bp) {
    grid-column: 2;
    grid-row: 4;
    margin-bottom: 30px;
  }
}

.section-copy__schemes {
  grid-column: 9 / span 4;
  grid-row: 1 / span 4;

  @media (--menu-bp) {
    display: block;
    grid-column: 2;
    grid-row: 4;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: left;
  }

  img {
    padding-right: 10px;
    width: 30%;

    @media (--menu-bp) {
      padding-right: 28px;
      box-sizing: content-box;
      max-width: 48%;
      width: 200px;
    }
  }
}

.section-copy__profile {
  grid-column: 2 / span 2;
  grid-row: 4;

  @media (width < 1500px) {
    grid-column: 2 / span 3;
  }

  @media (--menu-bp) {
    grid-column: 2;
    grid-row: 5;
  }
}

.section-copy__pedigree {
  grid-column: 4 / span 2;
  grid-row: 4;

  @media (width < 1500px) {
    grid-column: 5 / span 3;
  }

  @media (--menu-bp) {
    grid-column: 2;
    grid-row: 6;
  }
}

.section-copy__contact {
  grid-column: 6 / span 2;
  grid-row: 4;

  @media (width < 1500px) {
    grid-column: 8 / span 3;
  }

  @media (--menu-bp) {
    grid-column: 2;
    grid-row: 7;
  }
}

.section-copy__profile,
.section-copy__pedigree,
.section-copy__contact {
  /* .btn {
    @mixin proxima;
    padding-left: 0;
    padding-right: 0;
    margin-top: 60px;
    width: 100%;

    @media (--menu-bp) {
      margin-top: 28px;
    }
  } */
}

.single-horses {
  .section-3col {
    padding-top: 0;
  }

  .section-copy--current-offers {
    padding-bottom: 50px;
    padding-top: 130px;
  }
}

.section-copy__heading {
  grid-column: 2 / span 10;

  @media (--menu-bp) {
    grid-column: 2 / span 1;
    grid-row: 2;
  }
}

.section-copy__heading--7col {
  grid-column: 2 / span 7;

  @media (--menu-bp) {
    grid-column: 2 / span 1;
  }
}

.section-copy__copy {
  grid-column: 2 / span 7;
  margin-top: 30px;
  grid-row: 2;

  @media (--menu-bp) {
    margin-top: 50px;
    grid-column: 2 / span 1;
    grid-row: 3;
  }

  a {
    color: var(--c-gold);
    text-decoration: none;

    &:hover {
      opacity: 0.75;
    }
  }

  .section-copy--honour-roll-intro & {
    margin-top: 70px;

    @media (--xl) {
      grid-column: 2 / span 10;
      margin-top: 6.5vh;
    }

    @media (--menu-bp) {
      margin-top: 50px;
      grid-column: 2 / span 1;
    }
  }
}

.section-copy__copy--6 {
  grid-column: 2 / span 6;

  @media (--menu-bp) {
    grid-column: 2 / span 1;
  }
}

/* UTILITY */

.btn {
  --btn-bg-color: none;
  --btn-color: var(--c-gold);
  --btn-color-alt: var(--c-white); /* Text color on hover */
  --btn-padding: 15px 45px;
  --btn-padding-mobile: 12px 45px;
  --btn-font-size: 18px;
  --btn-letter-spacing: 0.2em;

  /* Reset for when the style is used with an actual button */
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  background-color: var(--btn-bg-color);
  border: 1px solid var(--btn-color);
  color: var(--btn-color);
  display: inline-block;
  font-size: var(--btn-font-size);
  letter-spacing: var(--btn-letter-spacing);
  line-height: 1em;
  margin: 40px 0 0;
  padding: var(--btn-padding);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;

  &:hover,
  &:focus {
    background-color: var(--btn-color);
    color: var(--btn-color-alt);
  }

  &:focus {
    outline: none;
  }

  @media (--md) {
    padding: var(--btn-padding-mobile);
    line-height: 24px;
  }

  /* Full width on mobile */
  @media (--sm) {
    text-align: center;
    width: 100%;

    .page-template-services & {
      width: unset;
    }
  }
}

.p-200 {
  padding-bottom: 200px;

  @media (--menu-bp) {
    padding-bottom: 100px;
  }
}

.bg-cream {
  background-color: var(--c-cream);
}

.p-65 {
  padding-top: 65px;
}

.p-60 {
  margin-top: 60px;
}

.pb-20 {
  padding-bottom: 10px;
}

.spacer-50 {
  height: 50px;
}

.spacer-140 {
  height: 140px;
}

.a-gold {
  color: var(--c-gold);
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    opacity: 0.75;
  }
}

.contact-link {
  color: var(--c-grey);
  transition: 0.3s;

  &:hover {
    color: var(--c-gold);
  }
}

.section-split {
  background-color: var(--c-cream);
  column-gap: 28px;
  display: grid;
  grid-template-columns: calc(16px + (100vw / 12)) repeat(10, 1fr) calc(
      16px + (100vw / 12)
    );

  @media (--menu-bp) {
    column-gap: 0;
    grid-template-columns: 1fr;
  }
}

.section-split__copy {
  grid-column: 8 / span 4;
  padding: 20.4vh 0 18.5vh;

  @media (width < 1650px) {
    grid-column: 7 / span 6;
    margin: auto;
    padding: 3vh 5vh 3vh;
  }

  @media (--menu-bp) {
    grid-column: 1;
    padding: 80px 25px 90px;
  }

  .small {
    text-transform: uppercase;
  }

  .copy {
    margin-top: 30px;
  }
}

.section-split__img {
  grid-column: 1 / span 6;

  @media (--menu-bp) {
    grid-column: 1;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;

    @media (--menu-bp) {
      height: auto;
      object-fit: unset;
    }
  }
}

/* critical:start */

.section-2col {
  column-gap: 28px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 0 50px 80px;

  @media (--menu-bp) {
    padding: 0 25px 50px;
  }

  @media (--md) {
    column-gap: 0;
    row-gap: 1em;
    grid-template-columns: 1fr;
  }
}

.section-2col--bloodstock {
  padding-bottom: 200px;

  @media (--menu-bp) {
    padding-bottom: 75px;
    row-gap: 20px;
  }
}

.section-2col__left {
  grid-column: 2 / span 4;

  @media (--menu-bp) {
    grid-column: 1 / span 12;
  }

  @media (--md) {
    grid-column: 1;
  }
}

.section-2col__right {
  grid-column: 6 / span 4;

  @media (--menu-bp) {
    grid-column: 1 / span 12;
    grid-row: 2;
    padding-top: 28px;
  }

  @media (--md) {
    grid-column: 1;
  }
}

.section-2col-wide__left {
  grid-column: 2 / span 5;
  padding-right: 3.2vw;

  @media (--menu-bp) {
    grid-column: 1 / span 12;
    padding-right: 0;
  }

  @media (--md) {
    grid-column: 1;
  }
}

.section-2col-wide__right {
  grid-column: 7 / span 5;
  padding-right: 3.2vw;

  @media (--menu-bp) {
    grid-column: 1 / span 12;
    grid-row: 2;
    padding-right: 0;
  }

  @media (--md) {
    grid-column: 1;
  }
}

.section-mediagrid {
  column-gap: 28px;
  row-gap: 28px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 100px 50px 80px;

  @media (--menu-bp) {
    padding: 50px 25px 40px;
  }

  @media (--md) {
    column-gap: 0;
    grid-template-columns: 1fr;
  }
}

.section-mediagrid-photo {
  grid-column: 2 / span 6;
  grid-row: 1 / span 2;

  @media (--menu-bp) {
    grid-column: 1 / span 7;
  }

  @media (--md) {
    grid-column: 1;
    grid-row: 1;
  }

  img {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.section-mediagrid-video1 {
  grid-column: 8 / span 4;
  grid-row: 1;

  @media (--menu-bp) {
    grid-column: 8 / span 5;
  }

  @media (--md) {
    grid-column: 1;
    grid-row: 2;
  }
}

.section-mediagrid-video2 {
  grid-column: 8 / span 4;
  grid-row: 2;

  @media (--menu-bp) {
    grid-column: 8 / span 5;
  }

  @media (--md) {
    grid-column: 1;
    grid-row: 3;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section-header {
  align-items: center;
  column-gap: 28px;
  display: grid;
  grid-template-columns: calc(16px + (100vw / 12)) repeat(10, 1fr) calc(
      16px + (100vw / 12)
    );
  padding-top: 210px;

  @media (--menu-bp) {
    column-gap: 0;
    grid-template-columns: 25px 1fr 25px;
    padding: 135px 0 0;

    .line {
      margin-bottom: 20px;
      width: 120px;
    }
  }
}

.section-header--horse {
  .section-header__copy {
    margin-top: 30px;
  }
}

.section-header__heading {
  grid-column: 2 / span 9;

  @media (width < 1500px) {
    grid-column: 2 / span 8;
  }

  @media (--menu-bp) {
    grid-column: 2;
    grid-row: 2;
  }

  .page-template-contact & {
    grid-column: 2 / span 6;

    @media (--menu-bp) {
      grid-column: 2;
    }
  }
}

.section-header__copy {
  grid-column: 2 / span 6;
  grid-row: 2;
  margin-top: 70px;

  @media (width < 1500px) {
    grid-column: 2 / span 10;
  }

  @media (--xl) {
    margin-top: 6.5vh;
  }

  @media (--menu-bp) {
    grid-row: 3;
    grid-column: 2;
  }

  .single-horses & {
    p {
      @mixin heading7;
    }
  }

  a {
    color: var(--c-gold);
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      opacity: 0.75;
    }
  }
}

.section-header__info {
  align-self: flex-start;
  grid-column: 11 / span 2;
  grid-row: 1 / span 2;

  @media (width < 1500px) {
    grid-column: 10 / span 3;
  }

  @media (--menu-bp) {
    grid-column: 2;
    grid-row: 3;
    padding-top: 50px;
  }

  .single-horses & {
    grid-row: 4;
  }

  .page-template-contact & {
    display: flex;
    grid-column: 8 / span 5;
    justify-content: flex-end;

    @media (--menu-bp) {
      grid-column: 2;
      justify-content: flex-start;
    }
  }

  .heading5 {
    margin-bottom: 10px;
  }

  a {
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s;

    &:hover {
      opacity: 0.75;
    }
  }

  .blog &,
  .archive & {
    margin-bottom: 140px;
  }
}

.proprietor {
  margin-right: 3vw;
  width: 360px;

  @media (--menu-bp) {
    width: 100%;
  }
}

.section-copy__info {
  align-self: flex-start;
  grid-column: 11 / span 2;
  grid-row: 1 / span 2;

  @media (width < 1500px) {
    grid-column: 10 / span 3;
  }

  @media (--menu-bp) {
    grid-column: 2 / span 2;
    grid-row: 3;
    padding-top: 20px;
  }
}

.semibold.current {
  color: var(--c-black);
}

.section-header__subscribe {
  grid-column: 11 / span 2;
  grid-row: 3;
  padding: 140px 0 40px;

  @media (width < 1500px) {
    grid-column: 10 / span 3;
  }

  @media (--menu-bp) {
    grid-column: 1;
    padding-top: 50px;
    row-column: 3;
  }

  a {
    text-decoration: none;
    text-transform: uppercase;

    @media (--menu-bp) {
      a {
        text-align: right;
      }
    }
  }
}
/* critical:end */

.section-3col {
  column-gap: 28px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 110px 50px 100px;

  @media (--menu-bp) {
    padding: 55px 25px 50px;
  }

  @media (--sm) {
    column-gap: 0;
    grid-template-columns: 1fr;
  }

  .post-type-archive-horses &,
  .tax-archive &,
  .blog & {
    padding-bottom: 0;
  }
}

.section-3col--current-offers {
  padding-top: 0;
}

.tax-archive .section-3col--current-offers,
.post-type-archive-horses .section-3col--current-offers {
  padding-top: 170px;

  @media (--md) {
    padding-top: 85px;
  }
}

.section-3col__article,
.section-3col__column {
  position: relative;
  grid-column: span 4;
  margin-bottom: 65px;

  @media (--menu-bp) {
    grid-column: span 6;
  }

  @media (--sm) {
    grid-column: span 1;
  }

  a {
    text-decoration: none;
    transition: 0.3s;

    .heading6 {
      padding-top: 10px;
      transition: 0.3s;
    }

    img {
      transition: 0.3s;
    }

    &:hover {
      img {
        opacity: 0.75;
      }

      .heading6 {
        color: var(--c-gold);
      }

      p,
      h2 {
        /* opacity: 1; */
      }
    }
  }

  .ribbon {
    height: 75px;
    overflow: hidden;
    position: absolute;
    right: -5px;
    text-align: right;
    top: -5px;
    width: 75px;
    z-index: 1;
  }
  .ribbon span {
    @mixin proxima-semibold;
    color: #fff;
    font-size: 14px;
    /* font-weight: bold; */
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#dcaf46 0%, #dcaf46 100%);
    /* box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1); */
    position: absolute;
    top: 19px;
    right: -21px;
  }
  .ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #dcaf46;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #dcaf46;
  }
  .ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #dcaf46;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #dcaf46;
  }

  img {
    /* Keep as 'display: inline; so hover opacity takes effect */
    /* display: block; */
    max-width: 100%;
  }

  p:not(.small) {
    padding-top: 15px;
  }

  .small {
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 0;
  }

  strong {
    color: var(--c-black);
  }
}

.section-3col__article--tablet {
  display: none;

  @media (--menu-bp) {
    display: block;
  }

  @media (--sm) {
    display: none;
  }
}

.section-3col--honour-roll {
  background-color: var(--c-cream);

  .section-3col__column {
    @media (--menu-bp) {
      grid-column: span 12;
      margin-bottom: 20px;
    }
  }
}

.section-3col__column {
  @media (--md) {
    grid-column: span 12;
  }
}

.current-offers-slider__slide {
  .ribbon {
    height: 75px;
    overflow: hidden;
    position: absolute;
    right: 14px;
    text-align: right;
    top: -5px;
    width: 75px;
    z-index: 1;
  }

  .ribbon span {
    @mixin proxima-semibold;
    color: #fff;
    font-size: 14px;
    /* font-weight: bold; */
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#dcaf46 0%, #dcaf46 100%);
    /* box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1); */
    position: absolute;
    top: 19px;
    right: -21px;
  }
  .ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #dcaf46;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #dcaf46;
  }
  .ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #dcaf46;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #dcaf46;
  }
}

.home,
.page-template-honour-roll,
.single-horses {
  .current-offers-slider__slide {
    margin-top: 5px;
  }

  .current-offers-slider__slide .ribbon {
    right: -5px;
  }
}

#year {
  @mixin proxima-semibold;
  appearance: none; /* Remove default arrow */
  background-image: url(img/chevron-down.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center right 10px;
  border: 1px solid var(--c-gold);
  background-color: white;
  color: var(--c-gold);
  font-size: 15px;
  letter-spacing: 0.05em;
  margin-right: 50px;
  outline: none;
  padding: 5px 10px;
  width: calc(100% - 50px);

  @media (--menu-bp) {
    margin-right: 25px;
    width: calc(100% - 25px);
  }
}

.current-offers-slider {
  margin: 0 calc(50px - 14px) 180px;
  padding-top: 50px;

  @media (--menu-bp) {
    margin: 0 calc(25px - 14px) 120px;
    padding-top: 65px;
  }

  .slick-slide img {
    /* Change for hover opacity to work */
    display: inline-block;
  }

  a {
    outline: 0;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      opacity: 0.75;

      p,
      h2 {
        opacity: 0.75;
      }
    }
  }

  .heading6 {
    padding-top: 2.78vh;
  }

  p {
    padding-top: 15px;
  }

  .slick-prev {
    height: 40px;
    left: unset;
    right: 130px;
    /* top: -68px; */
    top: 20px;
    width: 90px;
    z-index: 1;

    @media (--menu-bp) {
      /* top: -35px; */
      top: 30px;
    }

    @media (width < 568px) {
      left: 14px;
      right: unset;
      top: unset;
      bottom: -90px;
    }
  }

  .slick-next {
    height: 40px;
    right: 20px;
    /* top: -68px; */
    top: 20px;
    width: 100px;
    z-index: 1;

    @media (--menu-bp) {
      /* top: -35px; */
      top: 30px;
    }

    @media (width < 568px) {
      right: 20px;
      top: unset;
      bottom: -90px;
    }
  }

  .slick-prev:before {
    @mixin proxima;
    background: url(img/chevron-left-slick.svg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: 8px;
    box-sizing: border-box;
    color: var(--c-gold);
    content: "PREV";
    display: block;
    font-size: 18px;
    letter-spacing: 0.2em;
    margin: 0 auto;
    padding-left: 20px;
    width: 100px;
  }

  .slick-next:before {
    @mixin proxima;
    background: url(img/chevron-right-slick.svg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 8px;
    box-sizing: border-box;
    color: var(--c-gold);
    content: "NEXT";
    display: block;
    font-size: 18px;
    letter-spacing: 0.2em;
    margin: 0 auto;
    padding-right: 20px;
    text-align: right;
    width: 100px;
  }
}

.current-offers-slider__slide {
  margin: 0 14px;

  p {
    padding-right: 16px;
  }
}

.current-offers-slider__img {
  max-width: 100%;
}

.current-offers-slider--off {
  column-gap: 28px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin: 0;
  padding: 0 50px;

  margin-bottom: 180px;

  @media (--menu-bp) {
    grid-template-columns: 1fr;
    margin-bottom: 100px;
    padding: 0 25px;
  }

  .current-offers-slider__slide {
    grid-column: 2 / span 4;
    margin: 0;

    @media (--menu-bp) {
      grid-column: 1;
      margin-bottom: 80px;
    }

    &:nth-child(2) {
      grid-column: 6 / span 4;

      @media (--menu-bp) {
        grid-column: 1;
      }
    }
  }
}

.section-copy__sold-button {
  grid-column: 8 / span 4;
  text-align: right;

  @media (--menu-bp) {
    grid-column: 2;
    grid-row: 4;
    text-align: left;
    display: none;
  }
}

.section-copy__sold-button--mobile {
  display: none;

  @media (--menu-bp) {
    display: block;
    margin-top: -140px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
  }
}

/* Section Services */

.section-services {
  column-gap: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 0;
  padding-bottom: 0;

  @media (--menu-bp) {
    grid-template-columns: 1fr;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .page-template-about & {
    padding-bottom: 200px;

    @media (--md) {
      padding-bottom: 100px;
    }
  }

  .page-template-bloodstock &,
  .page-template-farm-development &,
  .page-template-services & {
    padding-bottom: 200px;
    background-color: var(--c-cream);

    @media (--menu-bp) {
      padding-bottom: 100px;
    }
  }
}

.section-services__image-left,
.section-services__image-right {
  grid-row: 1;
  position: relative;
  z-index: 0;

  &:after {
    background-color: var(--c-black);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s;
  }

  &.hover-toggle,
  &:hover {
    &:after {
      opacity: 0.25;
    }
  }
}

.section-services__image-left {
  grid-column: 1;

  @media (--menu-bp) {
    grid-column: 1;
    grid-row: 1;
  }
}

.section-services__image-right {
  grid-column: 2;

  @media (--menu-bp) {
    grid-column: 1;
    grid-row: 3;
  }
}

.section-services__overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
  z-index: 1;
}

.section-services__overlay-wrapper {
  display: flex;
  /* height: 135px; */

  .page-template-about & {
    height: 185px;
  }

  @media (width < 2100px) {
    .page-template-about & {
      height: 215px;
    }
  }

  @media (width < 2480px) {
    .home & {
      height: 185px;
    }
  }

  @media (width < 1900px) {
    .home & {
      height: 215px;
    }
  }

  /* Services template and Bllodstock services not included, will change */

  @media (width < 2480px) {
    .page-template-farm-development & {
      height: 185px;
    }
  }

  @media (width < 1900px) {
    .page-template-farm-development & {
      height: 215px;
    }
  }

  @media (width < 1700px) {
    background-color: var(--c-blue);
    flex-direction: column;
    height: 100% !important;
  }
}

.section-services__overlay--left {
  grid-column: 1;
  grid-row: 1;

  @media (width < 1700px) {
    grid-column: 1;
    grid-row: 2;
    width: 100%;
  }

  @media (--menu-bp) {
    grid-column: 1;
    grid-row: 2;
  }
}

.section-services__overlay--right {
  grid-column: 2;
  grid-row: 1;

  @media (width < 1700px) {
    grid-column: 2;
    grid-row: 2;
  }

  @media (--menu-bp) {
    grid-column: 1;
    grid-row: 4;
  }
}

.section-services__overlay-title {
  background-color: #051852d4;
  color: var(--c-white) !important;
  margin-left: 50px;
  margin-bottom: 50px;
  padding-top: 25px;
  padding-left: 30px;
  width: 33%;

  @media (width < 1700px) {
    background-color: var(--c-blue);
    /* border-right: 1px solid var(--c-gold); */
    padding: 0;
    margin: 30px 25px 0;
    width: calc(100% - 50px);
  }

  @media (--menu-bp) {
    /* margin-bottom: 0; */
  }
}

.section-services__overlay-description {
  background-color: #051852d4;
  display: flex;
  margin-right: 50px;
  margin-bottom: 50px;
  padding: 25px 30px;
  width: 67%;

  @media (width < 1700px) {
    flex-grow: 1;
    margin: 0;
    padding: 30px 25px 50px;
    width: 100%;
  }

  @media (--menu-bp) {
    /* padding: 30px 25px 50px; */
  }

  p {
    color: var(--c-white);

    @media (width < 1700px) {
      font-size: 18px;
      line-height: 24px;
      width: 95%;
    }

    @media (--menu-bp) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.section-services__divider {
  border-left: 1px solid var(--c-gold);
  margin-right: 28px;

  @media (width < 1700px) {
    display: none;
  }
}

/* FOOTER  */

footer {
  background-color: var(--c-blue);
  border-top: 3px solid var(--c-gold);
  column-gap: 28px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 12vh 50px;
  row-gap: 28px;

  @media (--menu-bp) {
    padding: 80px 25px 50px;
  }

  @media (--menu-bp) {
    column-gap: 0;
    grid-template-columns: 1fr;
  }

  .heading7 {
    color: var(--c-white);
    padding-bottom: 30px;

    @media (--menu-bp) {
      margin-top: 60px;
    }
  }

  p {
    color: var(--c-white);

    @media (--xl) {
      font-size: 18px;
      line-height: 1.4em;
    }

    @media (--menu-bp) {
      font-size: 18px;
    }
  }

  a {
    color: var(--c-white);
    transition: 0.3s;
    text-decoration: none;

    &:hover {
      color: var(--c-gold);
      opacity: 0.75;
    }
  }
}

.footer__logo {
  grid-column: 1;

  img {
    max-width: 100%;
    width: 105px;
  }

  @media (--sm) {
    grid-column: 1;
    grid-row: 1;
  }
}

.footer__about {
  grid-column: 2 / span 3;

  @media (--footer-tablet) {
    grid-column: 2 / span 4;
  }

  @media (--footer-tablet2) {
    grid-column: 2 / span 5;
  }

  @media (--menu-bp) {
    grid-column: 1;
    grid-row: 2;
  }
}

.textwidget {
  padding-right: 3.2vw;
}

.footer__contact {
  grid-column: 5 / span 4;
  grid-row: span 2;

  @media (--footer-tablet) {
    grid-column: 6 / span 3;
  }

  @media (--footer-tablet2) {
    grid-column: 7 / span 3;
  }

  @media (--menu-bp) {
    grid-column: 1;
    grid-row: 3;
  }
}

.footer__credit {
  font-size: 15px;
  grid-column: 2 / span 6;
  grid-row: 3;

  @media (--menu-bp) {
    grid-column: 1;
    grid-row: 6;
  }

  @media (width < 375px) {
    font-size: 12px;
  }
}

.footer__copyright {
  grid-column: 2 / span 3;
  grid-row: 2;

  @media (--footer-tablet) {
    grid-column: 2 / span 4;
  }

  @media (--footer-tablet2) {
    grid-column: 2 / span 5;
  }

  @media (--menu-bp) {
    padding: 80px 0 40px;
    grid-column: 1;
    grid-row: 5;
  }
}

.social-media {
  align-items: center;
  display: flex;
  margin-top: 30px;
}

.social-media__link {
  display: inline-block;
  margin-right: 10px;
}

.social-media__link svg {
  width: 26px;
  height: 26px;
}

.social-media__link:hover svg {
  transform: scale(1.25);
  transition: 0.3s;
}

.social-media__link:hover svg path {
  fill: var(--c-gold);
}

.footer__contact-details {
  display: flex;

  @media (--footer-tablet) {
    flex-direction: column;
  }

  @media (--menu-bp) {
    flex-direction: column;
    grid-row: 3;
  }

  .details-left,
  .details-right {
    flex-grow: 1;
  }

  .details-gap {
    height: 18px;
    width: 28px;
  }
}

.footer__subscribe {
  grid-column: 9 / span 4;

  @media (--footer-tablet2) {
    grid-column: 10 / span 3;
  }

  @media (--menu-bp) {
    grid-column: 1;
    grid-row: 4;
  }
}

/* CONTACT */

.page-template-contact {
  .section-header {
    padding-bottom: 150px;

    @media (--md) {
      padding-bottom: 75px;
    }
  }
}

.contact-address {
  column-gap: 28px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (width < 1540px) {
    grid-template-columns: 1fr;
  }

  @media (--menu-bp) {
    grid-template-columns: 1fr 1fr;
  }

  @media (--sm) {
    grid-template-columns: 1fr;
  }
}

.contact-address--email {
  margin-top: 20px !important;
}

.contact-address--phone {
  margin-bottom: 20px !important;
}

.contact-address--phone a {
  text-decoration: none;

  &:hover {
    opacity: 0.75;
  }
}

.contact-address__street {
  @media (width < 1540px) {
    padding-top: 1em;
  }

  @media (--menu-bp) {
    padding-top: 0;
  }

  @media (--sm) {
    padding-top: 1em;
  }
}

.section-copy--find-dgr {
  padding: 0;
  margin-bottom: 140px;

  @media (--menu-bp) {
    margin: 100px 0;
  }

  .line {
    grid-row: 2;
  }

  .section-copy__heading {
    grid-column: 2 / span 5;
    grid-row: 2;

    @media (--menu-bp) {
      grid-row: 3;
    }
  }

  .section-copy__copy {
    grid-row: 3;

    @media (--menu-bp) {
      grid-row: 4;
    }
  }
}

.section-copy__copy--find-dgr {
  grid-column: 2 / span 4;
  margin-top: 100px;

  @media (width < 1540px) {
    margin-top: 50px;
  }

  path {
    fill: var(--c-grey);
  }

  .social-media {
    margin-top: 70px;

    @media (--menu-bp) {
      margin-top: 30px;
    }
  }
}

.section-copy__top-padder {
  grid-column: 1 / span 6;
  padding-top: 16vh;

  @media (--menu-bp) {
    padding-top: 0;
  }
}

.section-copy__bottom-padder {
  grid-column: 1 / span 6;
  grid-row: 4;
  padding-bottom: 18vh;

  @media (--menu-bp) {
    grid-row: 5;
    padding-bottom: 8vh;
  }
}

.section-copy__half {
  grid-column: 7 / span 6;
  grid-row: 1 / span 4;

  @media (--menu-bp) {
    grid-column: 1 / span 3;
    grid-row: 6;
  }

  .section-copy__map {
    height: 100%;
    object-fit: none;
    width: 100%;

    @media (--menu-bp) {
      height: 450px;
    }
  }

  @media (--menu-bp) {
    grid-row: span 5;
  }
}

.section-copy--conversation {
  background-color: var(--c-cream);
  padding-top: 13.8vh;
  padding-bottom: 16.6vh;

  .section-copy__half {
    grid-column: 7 / span 5;
    grid-row: 2 / span 4;
    margin-top: 50px;

    @media (--menu-bp) {
      grid-column: 2;
      grid-row: 4;
    }
  }
}

.section-copy__copy--conversation {
  grid-column: 2 / span 5;
  margin-top: 50px;
  padding-right: 3.2vw;

  .contact-address {
    margin-bottom: 70px;
    margin-top: 70px;
  }
}

/* CONTACT FORM */

.mc4wp-form,
.hf-form {
  --background-color: unset; /* Of input fields */
  --border: 1px solid var(--c-black); /* Change below if only want border-bottom line 110 */
  --font: proxima-nova, sans-serif;
  --font-color: var(--c-black);
  --font-color-label: var(--c-black);
  --font-size: 20px;
  --font-weight: 400;
  --form-width: 100%;
  --letter-spacing: 0.005em;
  --label-font-size-active: 0.5em;
  --label-padding-left: 0;
  --label-padding-left-focus: 0;
  --field-padding-top: 1.2em; /* Key value */
  --field-padding-right: 0;
  --field-padding-bottom: 0.6em;
  --field-padding-left: 0;
  --row-gap: 20px;

  --field-padding: var(--field-padding-top) var(--field-padding-right)
    var(--field-padding-bottom) var(--field-padding-left);
}

.mc4wp-form {
  --border: 1px solid var(--c-white); /* Change below if only want border-bottom line 110 */
  --font-color: var(--c-white);
  --font-color-label: var(--c-white);
}

.hf-form {
  box-sizing: content-box;
  font-size: var(--font-size);
  max-width: var(--form-width);
  margin: auto;
}

.has-float-label {
  display: block;
  margin-bottom: var(--row-gap);
  position: relative;

  textarea {
    height: 150px;
    width: 100%;
  }
}

.has-float-label label,
.has-float-label > span {
  color: var(--font-color-label);
  cursor: text;
  font-size: var(--label-font-size-active);
  font-family: var(--font);
  font-weight: var(--font-weight);
  letter-spacing: var(--letter-spacing);
  left: 0;
  opacity: 1;
  padding-left: var(--label-padding-left-focus);
  position: absolute;
  top: 3px;
  transition: all 0.2s;
}

.has-float-label input,
.has-float-label select,
.has-float-label textarea {
  border: 0;
  border-radius: 0;
  padding-top: 1em;
}

.has-float-label input::placeholder,
.has-float-label select::placeholder,
.has-float-label textarea::placeholder {
  opacity: 1;
  transition: all 0.2s;
}

.has-float-label input:placeholder-shown:not(:focus)::placeholder,
.has-float-label select:placeholder-shown:not(:focus)::placeholder,
.has-float-label textarea:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

/* State before any data entered */
.has-float-label input:placeholder-shown:not(:focus) + *,
.has-float-label select:placeholder-shown:not(:focus) + *,
.has-float-label textarea:placeholder-shown:not(:focus) + * {
  font-size: var(--font-size);
  opacity: 1;
  padding-left: var(--label-padding-left);
  top: var(--field-padding-top);
}

.has-float-label input:focus,
.has-float-label select:focus,
.has-float-label textarea:focus {
  outline: 0;
}

.has-float-label input,
.has-float-label select,
.has-float-label textarea {
  border-bottom: var(--border); /* Change "border" to "border-bottom" */
  background-color: var(--background-color);
  box-sizing: border-box;
  color: var(--font-color);
  font-family: var(--font);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  font-style: normal;
  letter-spacing: var(--letter-spacing);
  padding: var(--field-padding);
  width: 100%;
}

.hf-message {
  color: var(--font-color);
  font-family: var(--font);
  font-size: var(--font-size);
  font-weight: bold;
  margin: 20px 0;
}

.hf-form,
.mc4wp-form {
  .btn {
    @mixin proxima;
    margin: 0;
    outline: 0;
  }
}

.mc4wp-form .btn {
  margin-top: 30px;
}

/* CONTACT FORM ENDS  */

.media-grid {
  display: flex;
  margin: 0 36px 150px;

  @media (--menu-bp) {
    flex-wrap: wrap;
    margin: 0 11px 75px;
  }

  @media (--md) {
  }
}

.media-grid__column {
  width: 25%;

  @media (--menu-bp) {
    width: 50%;
  }
}

.media-grid__column-item {
  box-sizing: border-box;
  padding: 14px;
}

.media-grid__item {
  box-sizing: border-box;
  padding: 14px;
  width: 25%;

  @media (--menu-bp) {
    width: 50%;
  }
}

.media-grid__image {
  display: block;
  max-width: 100%;
}

/* TIMELINE */

.timeline {
  background-color: var(--c-cream);
  column-gap: 28px;
  display: grid;
  grid-template-columns: calc(16px + (100vw / 12)) repeat(11, 1fr);
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 50px;
  position: relative;

  &:before {
    background-color: var(--c-gold);
    bottom: 0;
    content: "";
    left: 50%;
    margin-left: -1px;
    position: absolute;
    top: 0;
    width: 2px;

    @media (--menu-bp) {
      content: none;
    }
  }

  @media (--menu-bp) {
    column-gap: 0;
    grid-template-columns: 25px 1fr 25px;
    padding-right: 0;
  }

  .heading7 {
    padding-bottom: 15px;
  }

  .section-copy__heading {
    grid-column: 2 / span 5;
  }

  .line {
    align-self: center;
    margin-bottom: 5px;

    @media (--menu-bp) {
      padding-bottom: 20px;
      width: 120px;
    }
  }

  .section-copy__heading {
    @media (--menu-bp) {
      padding-bottom: 50px;
    }
  }
}

.timeline__right {
  grid-column: 8 / span 5;
  grid-row: span 2;
  margin-top: 100px;
  padding-right: 3.2vw;

  @media (--menu-bp) {
    border-left: 2px solid var(--c-gold);
    grid-column: 2 / span 1;
    grid-row: span 1;
    padding-left: 30px;
    padding-right: 0;
    margin-top: 0;
    padding-top: 100px;
  }
}

.timeline__left {
  grid-column: 2 / span 5;
  grid-row: span 2;
  margin-top: 100px;
  padding-right: 3.2vw;

  @media (--menu-bp) {
    border-left: 2px solid var(--c-gold);
    grid-column: 2 / span 1;
    grid-row: span 1;
    padding-left: 30px;
    padding-right: 0;
    margin-top: 0;
    padding-top: 100px;
  }
}

.timeline__image {
  padding-top: 10px;
  width: calc(25vw - 50px);

  @media (width > 1400px) {
    .timeline--1 &,
    .timeline--2 &,
    .timeline--4 {
      width: calc(16.66vw - 42px);
    }
  }

  @media (--menu-bp) {
    max-width: 100%;
    width: 50vw;
  }

  @media (--sm) {
    max-width: 100%;
    width: 100%;
  }
}

.timeline--1 {
  grid-row: span 3;
  margin-top: 0;

  @media (--menu-bp) {
    grid-column: 2 / span 1;
    grid-row: 3 / span 1;
    padding-top: 0;
  }
}

.timeline__knob {
  grid-column: 7;
  margin-left: -14px;
  margin-top: 100px;

  &:after {
    background-color: var(--c-cream);
    border: 2px solid var(--c-gold);
    border-radius: 8px;
    content: "";
    height: 16px;
    margin-left: -8px;
    position: absolute;
    width: 16px;
    z-index: 1;
  }

  @media (--menu-bp) {
    margin-left: 1px;
  }
}

.timeline__knob--1 {
  margin-top: 0;

  @media (--menu-bp) {
    grid-column: 2;
    grid-row: 3;
  }
}

.timeline__knob--2,
.timeline--2 {
  grid-row: 3 / span 2;

  @media (--menu-bp) {
    grid-column: 2 / span 1;
    grid-row: 4 / span 1;
  }
}

.timeline__knob--3,
.timeline--3 {
  grid-row: 4 / span 2;

  @media (--menu-bp) {
    grid-column: 2 / span 1;
    grid-row: 5 / span 1;
  }
}

.timeline__knob--4,
.timeline--4 {
  grid-row: 5 / span 2;

  @media (--menu-bp) {
    grid-column: 2 / span 1;
    grid-row: 6 / span 1;
  }
}

.timeline__knob--5,
.timeline--5 {
  grid-row: 6 / span 2;

  @media (--menu-bp) {
    grid-column: 2 / span 1;
    grid-row: 7 / span 1;
  }
}

.timeline__knob--6,
.timeline--6 {
  grid-row: 7 / span 2;

  @media (--menu-bp) {
    grid-column: 2 / span 1;
    grid-row: 8 / span 1;
  }
}

.timeline__knob--7,
.timeline--7 {
  grid-row: 8 / span 2;

  @media (--menu-bp) {
    grid-column: 2 / span 1;
    grid-row: 9 / span 1;
  }
}

.timeline__knob--8,
.timeline--8 {
  grid-row: 9 / span 2;

  @media (--menu-bp) {
    grid-column: 2 / span 1;
    grid-row: 10 / span 1;
  }
}

.timeline__knob--last {
  grid-row: 9;
  margin-top: 0;

  @media (--menu-bp) {
    grid-column: 2;
  }
}

.trophy-room {
  p {
    display: flex;
    margin-top: 0;
  }

  img {
    padding-left: 6px;
  }
}

/*--------------------------------------------------------------
# Accordion
--------------------------------------------------------------*/

.accordion {
  column-gap: 28px;
  display: grid;
  grid-template-columns: calc(16px + (100vw / 12)) repeat(10, 1fr) calc(
      16px + (100vw / 12)
    );

  @media (--menu-bp) {
    column-gap: 0;
    grid-template-columns: 25px 1fr 25px;
  }
}

.accordion__button {
  align-items: center;
  background-color: unset;
  border: none;
  border-top: 1px solid var(--c-grey);
  cursor: pointer;
  display: flex;
  grid-column: 2 / span 10;
  justify-content: space-between;
  padding: 20px 0;
  text-align: left;
  outline: none;
  width: 100%;

  &:first-child {
    border-top: 0;
  }

  @media (--menu-bp) {
    grid-column: 2;
  }
}

.accordion__button img {
  padding: 7px;
  transform: rotate(0);
  transition: 0.5s;
}

.accordion__button.active img {
  transform: rotate(-225deg);
  transition: 0.5s;
}

.accordion__panel {
  grid-column: 2 / span 10;
  overflow: hidden;
  transition: max-height 0.5s;

  column-gap: 28px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);

  &.accordion__panel--closed {
    max-height: 0;
  }

  &.accordion__panel--open {
    max-height: none;
  }

  @media (--menu-bp) {
    column-gap: 0;
    row-gap: 28px;
    grid-column: 2;
    grid-template-columns: 1fr;
  }
}

.accordion__description {
  grid-column: 7 / span 4;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (width < 1200px) {
    padding-top: 0;
  }

  @media (--menu-bp) {
    grid-column: 1;
    padding: 0 0 30px;
  }
}

.accordion__photo {
  grid-column: 1 / span 5;
  padding-bottom: 80px;

  @media (--menu-bp) {
    grid-column: 1;
    padding: 0;
  }

  img {
    max-width: 100%;
  }
}

.accordion-bottom {
  border-top: 1px solid var(--c-grey);
  grid-column: 2 / span 10;
  margin-bottom: 200px;
}

/* POST */

.attachment-post-thumbnail {
  max-width: 100%;
}

figure {
  padding-bottom: 40px;
  padding-top: 50px;
  
  &.wp-block-image {
    margin-bottom: 0;
  }

  &.thumbnail-feature {
    padding-top: 0;
    padding-bottom: 60px;
  }

  @media (--menu-bp) {
    padding: 50px 0 40px;
  }

  img {
    height: auto;
  }
}

@define-mixin article-info {
  @mixin proxima;
  color: var(--c-grey);
  font-size: 15px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}

figcaption {
  @mixin article-info;
  text-transform: none;
}

.section-copy__date {
  @mixin article-info;
  grid-column: 2 / span 2;

  @media (width < 1300px) {
    grid-column: 2 / span 3;
  }

  @media (--menu-bp) {
    grid-column: 1 / span 12;
    grid-row: 1;
  }
}

.section-copy__terms {
  @mixin article-info;
  grid-column: 4 / span 5;
  margin-top: 0;

  @media (width < 1300px) {
    grid-column: 5 / span 4;
  }

  @media (--menu-bp) {
    grid-column: 1 / span 12;
    grid-row: 2;
  }
}

.section-copy__terms-link {
  color: var(--c-gold);
}

.section-copy--article-info {
  grid-template-columns: repeat(12, 1fr);
  padding: 100px 50px 0;

  @media (--menu-bp) {
    padding: 50px 25px 0;
  }
}

.section-copy--article {
  padding-top: 0;
  padding-bottom: 0;

  a {
    color: var(--c-gold);
    text-decoration: none;
  }

  .section-copy__copy {
    @media (width < 1400px) {
      grid-column: 2 / span 10;
    }

    @media (--menu-bp) {
      grid-column: 2;
    }
  }
}

.section-copy__subscribe {
  @mixin proxima-semibold;
  color: var(--c-gold);
  font-size: 15px;
  letter-spacing: 0.075em;
  line-height: 28px;
  margin: 0;
  text-transform: uppercase;

  grid-column: 9 / span 4;
  text-align: right;
  text-decoration: none;

  @media (--menu-bp) {
    grid-column: 1 / span 12;
    grid-row: 3;
    text-align: left;
  }
}

.post-template-default {
  .section-header__heading {
    @media (width < 1840px) {
      grid-column: 2 / span 10;

      h1 {
        font-size: 60px;
        line-height: 1em;
      }
    }
  }
}

.single-post {
  .split-heading-top {
    display: none;

    @media (width < 1900px) {
      display: inline;
    }
  }

  .split-heading-bottom {
    @media (width < 1900px) {
      display: none;
    }
  }

  @media (width < 1900px) {
    h1::first-line {
      color: var(--c-gold);
    }
  }
}

.single-horses {
  .split-heading-top {
    display: none;

    @media (width < 1600px) {
      display: inline;
    }
  }

  .split-heading-bottom {
    @media (width < 1600px) {
      display: none;
    }
  }

  @media (width < 1900px) {
    h1::first-line {
      color: var(--c-gold);
    }
  }
}

.section-copy--more-articles {
  padding-bottom: 0;

  .section-copy__heading {
    grid-column: 2 / span 6;
  }

  .section-copy__newsbutton {
    grid-column: 8 / span 5;
    text-align: right;
    padding-right: 50px;

    .btn {
      margin: 0;
    }

    @media (--menu-bp) {
      display: none;
    }
  }
}

.newsbutton-mobile {
  display: none;

  @media (--menu-bp) {
    display: block;
    margin: 0 25px 140px;

    .btn {
      margin-top: 0;
    }
  }
}

.section-3col--more-articles {
  padding-top: 75px;

  .heading6 {
    padding-top: 1.85vh;
  }

  @media (--menu-bp) {
    padding-bottom: 0;
  }
}

blockquote {
  border-left: 2px solid var(--c-gold);
  margin: 20px 0;
  padding: 0 20px;
}

.navigation {
  padding-top: 100px;
}

.nav-links {
  @mixin proxima;

  display: flex;
  font-size: 18px;
  justify-content: center;
  margin-bottom: 100px;
  margin-top: 50px;

  @media (--sm) {
    flex-direction: column;
  }

  a {
    align-items: center;
    color: var(--c-gold);
    display: flex;
    letter-spacing: 0.2em;
    padding: 10px;
    margin: 0 22px;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.3s;

    @media (--sm) {
      display: inline-flex;
    }

    &:hover {
      opacity: 0.75;
    }
  }
}

.nav-previous a:before {
  content: url('data:image/svg+xml; utf8, <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.32691 18.1139C9.24236 18.1158 9.15836 18.0998 9.08039 18.0671C9.00241 18.0343 8.93222 17.9855 8.87438 17.9238L0.525191 9.58819C0.463851 9.52986 0.41501 9.45966 0.381637 9.38187C0.348264 9.30407 0.331055 9.22031 0.331055 9.13566C0.331055 9.05101 0.348264 8.96725 0.381637 8.88945C0.41501 8.81166 0.463851 8.74146 0.525191 8.68313L8.86532 0.320368C8.92388 0.255411 8.99543 0.203477 9.07533 0.16793C9.15524 0.132382 9.24172 0.114014 9.32917 0.114014C9.41662 0.114014 9.5031 0.132382 9.58301 0.16793C9.66291 0.203477 9.73446 0.255411 9.79301 0.320368C9.85679 0.377252 9.90782 0.446974 9.94276 0.524961C9.9777 0.602949 9.99576 0.687441 9.99576 0.772898C9.99576 0.858355 9.9777 0.942848 9.94276 1.02084C9.90782 1.09882 9.85679 1.16854 9.79301 1.22543L1.87826 9.11303L9.79301 17.0097C9.88304 17.0999 9.94442 17.2147 9.96945 17.3397C9.99448 17.4647 9.98204 17.5942 9.93371 17.7122C9.88538 17.8301 9.80329 17.9311 9.69776 18.0026C9.59222 18.0741 9.46793 18.1128 9.34048 18.1139H9.32691Z" fill="rgb(220, 175, 70)"/></svg>');
  display: inline-block;
  line-height: 1em;
  margin-right: 18px;
  transition: 0.3s;
}

.nav-next a:after {
  content: url('data:image/svg+xml; utf8, <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.997872 0.10977C1.08248 0.107877 1.16653 0.123837 1.24456 0.156608C1.32258 0.189378 1.39283 0.238224 1.45071 0.299962L9.80557 8.66388C9.86695 8.72225 9.91582 8.79249 9.94922 8.87034C9.98261 8.94819 9.99983 9.03201 9.99983 9.11671C9.99983 9.20142 9.98261 9.28524 9.94922 9.36309C9.91582 9.44093 9.86695 9.51118 9.80557 9.56955L1.45977 17.9154C1.40139 17.9767 1.33115 18.0256 1.2533 18.059C1.17546 18.0924 1.09163 18.1096 1.00693 18.1096C0.922221 18.1096 0.838399 18.0924 0.760554 18.059C0.682708 18.0256 0.612464 17.9767 0.55409 17.9154C0.490273 17.8584 0.439208 17.7887 0.404243 17.7106C0.369278 17.6326 0.351202 17.548 0.351202 17.4625C0.351202 17.377 0.369278 17.2924 0.404243 17.2144C0.439208 17.1364 0.490273 17.0666 0.55409 17.0097L8.45158 9.11671L0.531449 1.21469C0.441362 1.12441 0.379943 1.00953 0.354896 0.884477C0.329848 0.759419 0.342288 0.629749 0.390655 0.511735C0.439021 0.39372 0.521161 0.292616 0.626769 0.221106C0.732377 0.149596 0.85675 0.110864 0.984286 0.10977H0.997872Z" fill="rgb(220, 175, 70)"/></svg>');
  display: inline-block;
  line-height: 1em;
  margin-left: 18px;
  transition: 0.3s;
}

@media (--sm) {
  .nav-next,
  .nav-previous {
    text-align: center;
  }
}

.section-copy__buttons {
  display: flex;
  grid-column: 2 / span 9;

  @media (--menu-bp) {
    flex-direction: column;
    grid-column: 2 / span 1;
  }

  .btn {
    @mixin proxima;
    padding-left: 0;
    padding-right: 0;
    margin-right: 28px;
    margin-top: 60px;
    width: calc(33% - 28px);

    @media (--menu-bp) {
      margin-top: 28px;
      margin-right: 0;
      width: 50%;
    }

    @media (--sm) {
      width: 100%;
    }
  }
}

@media (--md) {
  #trophy-room-2,
  #trophy-room-3,
  #honour-roll-2,
  #honour-roll-3 {
    display: none;
  }
}

#more-trophy-room,
#more-trophy-room-2 {
  display: none;
  margin-top: 30px;
}

@media (--md) {
  #more-trophy-room,
  #more-trophy-room-2 {
    cursor: pointer;
    display: block;
  }
}

.view-more {
  @mixin proxima-semibold;
  align-items: center;
  color: var(--c-gold);
  display: flex;
  font-size: 18px;
  letter-spacing: 0.2em;
  padding-top: 20px;

  img {
    height: 16px;
    padding-left: 10px;
  }
}

.page-template-honour-roll {
  .section-copy--current-offers,
  .section-3col--current-offers,
  .section-copy__sold-button--mobile {
    background-color: var(--c-cream);
  }
}

@media (--sm) {
  .section-3col--trophyroom p {
    font-size: 18px;
  }
}

@media (--sm) {
  .page-template-contact .section-header__info p {
    font-size: 15px;
    line-height: 1.4;
  }
}

.error404 main {
  padding: 200px 50px 100px;

  @media (--menu-bp) {
    padding: 100px 30px 60px;
  }
}

.section-2col a {
  color: var(--c-gold);
  text-decoration: none;

  &:hover {
    opacity: 0.75;
  }
}